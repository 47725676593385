import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Prismic from "prismic-javascript";
import { Client } from "../prismic-config";
import Loader from "../Components/Loader/Loader";
import PostList from "../Components/Post/PostList/PostList";
import PostTagSidebar from "../Components/Post/PostTagSidebar/PostTagSidebar";
//Blog Function

export default function Blog() {
  const [PrismicData, setPrismicData] = useState({
    homeDoc: null,
    blogPosts: null,
  });
  const [, toggleNotFound] = useState(false);

  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const homeDoc = await Client.query(
          Prismic.Predicates.at("document.type", "feature_post"),
          { orderings: "[my.post.date desc]" }
        );
        const blogPosts = await Client.query(
          Prismic.Predicates.at("document.type", "requin_blog"),
          { orderings: "[my.requin_blog.public_date desc]" }
        );
        if (homeDoc) {
          setPrismicData({
            homeDoc: homeDoc.results,
            blogPosts: blogPosts.results,
          });
        } else {
          console.warn(
            "Blog Home document was not found. Make sure it exists in your Prismic repository"
          );
          toggleNotFound(true);
        }
      } catch (error) {
        console.error(error);
        toggleNotFound(true);
      }
    };
    fetchPrismicData();
  }, []);

  if (PrismicData.homeDoc) {
    return (
      <BlogContainer>
        <Container>
          <PostTagSidebar />
          <BlogWrapper>
            <ResponsiveText>
              <PostList posts={PrismicData.blogPosts} />
            </ResponsiveText>
          </BlogWrapper>
        </Container>
      </BlogContainer>
    );
  } else {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }
}

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  background: white;
  overflow: hidden;
  font-size=10pt;
  @media only screen and (max-width: 768px) {
    height: auto; /* Adjust height to auto for smaller screens */
  }
`;

const BlogContainer = styled.div`
  background-color: #fff;
  padding: 0 5vw;
  font-size=10pt;
  @media only screen and (max-width: 768px) {
    height: auto; 
    font-size=18pt;/* Adjust height to auto for smaller screens */
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  margin: 0 auto;
  font-size=10pt;
  @media only screen and (min-width:468px) {
    flex-direction: row;
    padding-top: 100px;
  }
`;

const BlogWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  padding: 0 10px;

  @media only screen and (min-width:468px) {
    max-width: 870px;
    padding: 0 20px;
  }
`;

const ResponsiveText = styled.div`
  display: flex;
  flex-direction: column;
  font-size=10pt;
  @media only screen and (min-width: 468px) {
    flex-direction: row;
  }
`;