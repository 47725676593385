import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

export default function AssignmentDetailsPage  ({ country })  {
  return (
    <>
      <Helmet>
        <title>{country} Page</title>
      </Helmet>
      <PageContainer>
        <AssignmentDetailsHeader>
          <h2>{`Instant Assignment Help for ${country} Students By Turbo Assignment`}</h2>
          <p>
            Pristine Quality Assignments by Our Highly Qualified Experts! Get
            affordable services now!
          </p>
        </AssignmentDetailsHeader>

        <GridContainer>
          <GridBox>
            <SectionBox>
              <SectionTitle>Why Choose TurboAssignment?</SectionTitle>
              <SectionContent>
                <p>
                  A student’s journey can be challenging, especially when
                  academic assistance is needed. The internet is rife with
                  scams and unreliable sources. Enter TurboAssignment – your
                  trusted ally in overcoming educational obstacles!
                </p>
                <p>
                  Navigate through your academic endeavors with confidence.
                  Turbo Assignment brings you a curated talent pool, ensuring
                  excellence in every assignment. Our platform is designed for
                  ease, making your educational journey a breeze.
                </p>
                <p>
                  Don't settle for average. Choose Turbo Assignment and soar
                  higher in your academic pursuits!
                </p>
              </SectionContent>
            </SectionBox>
          </GridBox>

          <GridBox>
            <SectionBox>
              <SectionTitle>What You Can Expect:</SectionTitle>
              <SectionContent>
                <ul>
                  <li>
                    <strong>Subject Variety:</strong> Turbo Assignment offers
                    writing, editing, and tutoring services for a vast array of
                    subjects. You name it, we've got a writer that’s skilled in
                    that area.
                  </li>
                  <li>
                    <strong>Quality Work and Checking:</strong> There are
                    certain standards that TurboAssignment has set for itself.
                    Any work that we deliver will always be well-researched,
                    fully-referenced, and plagiarism-free. Don’t worry about
                    quality! They’ve already got it handled.
                  </li>
                  <li>
                    <strong>Strong Client Service:</strong> If you need any
                    assistance with the order or have a general question, don’t
                    fret! TurboAssignment has an amazing team ready to offer
                    end-to-end support throughout your entire experience.
                  </li>
                </ul>
              </SectionContent>
            </SectionBox>
          </GridBox>

          <GridBox>
            <SectionBox>
              <SectionTitle>About TurboAssignment:</SectionTitle>
              <SectionContent>
                <p>
                  TurboAssignment isn’t just some ordinary assignment help
                  service company. They really do care about the users. They
                  want to make sure everything runs as smoothly as possible
                  from start to finish.
                </p>
              </SectionContent>
            </SectionBox>
          </GridBox>

          <GridBox>
            <SectionBox>
              <SectionTitle>Customer Reviews by {country} clients</SectionTitle>
              <SectionContent>
                <p>
                  Read what our clients say about our assignment help services
                  in {country}. We have been rated 4.7/5 based on 5563 customer
                  reviews and recommended by 85090+ students. Our commitment to
                  quality and excellence is reflected in the positive feedback
                  from our satisfied clients.
                </p>
              </SectionContent>
            </SectionBox>
          </GridBox>

          <GridBox>
            <SectionBox>
              <SectionTitle>Client Reviews</SectionTitle>
              <SectionContent>
                <Review>
                  <ReviewText>
                    "TurboAssignment has been a lifesaver! Their quick response
                    and high-quality work have made my academic journey much
                    smoother."
                  </ReviewText>
                  <ReviewAuthor>- Sarah Thompson</ReviewAuthor>
                </Review>
                <Review>
                  <ReviewText>
                    "I highly recommend TurboAssignment. Their team is
                    professional, and the assignments are always delivered on
                    time."
                  </ReviewText>
                  <ReviewAuthor>- John Miller</ReviewAuthor>
                </Review>
              </SectionContent>
            </SectionBox>
          </GridBox>
        </GridContainer>
      </PageContainer>
    </>
  );
}

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px; /* Added padding to the PageContainer */
`;

const AssignmentDetailsHeader = styled.div`
  text-align: center;
  margin-bottom: 40px;

  h2 {
    font-size: 32px;
    color: #7047ea;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    color: #5b6987;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr; /* Updated grid-template-columns for mobile */
  grid-gap: 20px;
`;

const GridBox = styled.div`
  margin-bottom: 20px;
`;

const SectionBox = styled.div`
  background-color: #f8f8fa;
  padding: 20px;
  border-radius: 12px;
`;

const SectionTitle = styled.h3`
  font-size: 24px;
  color: #7047ea;
  margin-bottom: 15px;
`;

const SectionContent = styled.div`
  p {
    font-size: 16px;
    color: #5b6987;
    margin-bottom: 15px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin-bottom: 10px;
  }
`;

const Review = styled.div`
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 20px;
`;

const ReviewText = styled.p`
  font-size: 16px;
  color: #5b6987;
  margin-bottom: 10px;
`;

const ReviewAuthor = styled.p`
  font-size: 14px;
  color: #9ea7b8;
`;


