import React from "react";
import styled from "styled-components";
var SList = [];
export default function QuizeQuestion(props) {
  SList = new Array(5).fill(0);
  function handleChange(e, key) {
    // e.preventDefault();
    if (e.target.value === props.data.answer) {
      SList[key] = 1;
    } else {
      SList[key] = -1;
    }
    // console.log(SList);
    props.Score(SList);
  }

  return (
    <>
      <Container key={props.k}>
        <Heading>
          <span>
            {props.k + 1}
            <div>
              <svg height="10" width="11">
                <path d="M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z"></path>
                <path d="M8 4v2H0V4z"></path>
              </svg>
            </div>
          </span>
          {props.data.quiz}
        </Heading>
        <UList>
          {props.data.option.map((e, value) => (
            <List>
              <Label htmlFor={e}>
                <input
                  type="radio"
                  id={e}
                  name={props.data.quiz}
                  value={value + 1}
                  onChange={(e) => handleChange(e, props.k)}
                ></input>
                <span>{e}</span>
              </Label>
            </List>
          ))}
        </UList>
      </Container>
    </>
  );
}

const Container = styled.div``;
const Heading = styled.div`
  font-size: 24px;
  display: flex;
  margin-bottom: 20px;
  color: #171d40;
  span {
    display: flex;
    margin-right: 10px;
  }
`;
const UList = styled.div`
  display: inline-flex;
  flex-direction: column;
  padding-left: 40px;
`;
const List = styled.div`
  margin: 20px;
`;
const Label = styled.label`
  cursor: pointer;
  margin: 5px 0;
  padding: 10px;
  cursor: pointer;
  color: #444141;
  border-radius: 5px;
  transition: 0.4s ease-in-out;
  padding-left: 20px;
  input {
    margin-right: 10px;
  }
  :hover {
    transition: 0.4s ease-in-out;
    border-radius: 10px;
    background-color: #efefef;
    color: black;
    padding-right: 22px;
  }
`;
