import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
export default function QuizItem(params) {
  return (
    <>
      <Conatiner>
        {quizelist_data.map((d) => {
          return (
            <QuizWrapper>
              <div
                style={{
                  // fontSize: '30px',
                  fontSize: '25px',
                  marginLeft: '20px',
                  fontWeight: '600',
                  padding: '10px',
                }}
              >
                {d.quizName}
              </div>

              <SubQuizWrapper>
                {d.tests.map((test) => {
                  return (
                    <ContenetWrapper to={`/quiz/${test.link}/start`}>
                      <ContenetContainer
                        style={{ objectFit: 'contain', cursor: 'pointer' }}
                      >
                        <img
                          src={test.img}
                          // width="100%"
                          // height="100%"
                          alt="img"
                          style={{
                            objectFit: 'cover',
                            width: '300px',
                            height: '300px',
                          }}
                        ></img>
                      </ContenetContainer>
                      <ContenetName>{test.name}</ContenetName>
                    </ContenetWrapper>
                  )
                })}
              </SubQuizWrapper>
            </QuizWrapper>
          )
        })}
      </Conatiner>
    </>
  )
}
const Conatiner = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`
const SubQuizWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
const QuizWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ContenetContainer = styled.div`
  img {
    border-radius: 5px;
  }
`
const ContenetName = styled.div`
  position: absolute;
  bottom: 0;
  font-weight: bold;
  color: white;
  margin-bottom: 5px;
  font-family: sans-serif;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s, opacity 0.5s linear;
  transition: visibility 0s, opacity 0.5s linear;
`
const ContenetWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 10px;
  :hover {
    opacity: 1;
  }
  :hover ${ContenetName} {
    width: 90%;
    padding: 22px 15px;
    visibility: visible;
    opacity: 1;
  }
`
const quizelist_data = [
  {
    quizName: 'HTML',
    tests: [
      {
        name: 'Test 1',
        link: 'asd123',
        questions: 5,
        img:
          'https://images.unsplash.com/photo-1546900703-cf06143d1239?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3391&q=80',
        time: 60,
      },
      {
        name: 'Test 2',
        link: 'asd1234',
        img:
          'https://images.unsplash.com/photo-1542831371-29b0f74f9713?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Y29kZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
        questions: 10,
        time: 120,
      },
    ],
  },
  {
    quizName: 'CSS',
    tests: [
      {
        name: 'Test 1',
        link: 'asd12345',
        questions: 5,
        img:
          'https://images.unsplash.com/photo-1487058792275-0ad4aaf24ca7?ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8Y29kZSUyMGNzc3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
        time: 60,
      },
      {
        name: 'Test 2',
        link: '/asd12',
        img:
          'https://images.unsplash.com/photo-1523437113738-bbd3cc89fb19?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGNvZGUlMjBjc3N8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
        questions: 10,
        time: 120,
      },
    ],
  },
]
