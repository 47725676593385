import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
function Protected(props) {
    let Cmp = props.Cmp
    const history = useHistory()
    useEffect(() => {
        if (!localStorage.getItem('Adminuser')) {
            history.push('/Admin')
        }
    })
    return (<>
        <Cmp />
    </>);
}

export default Protected;