/**
 * creating Faq component for the website
 * importing statements 
 */
import React from "react";
import styled from "styled-components";
import Faq from "react-faq-component";


const data = {
   
    rows: [
        {
            title: "Who Can Assist Me With My Assignment Writing ?",
            content: `If you’re having anxiety over your assignments, we have the solution. 
            Don’t worry. Turbo Assignment is here to help. We give comprehensive assistance in all subjects. With us, 
            you’ll have nothing to worry about. Why? Because we make sure that all assignments are done on time with the highest quality possible. 
            Our experts are committed to helping you succeed with your academic grades and goals. With Turbo Assignment, 
            you’ll update your email signature to something like this: “Turbo Assignment user”`,
        },
        {
            title: "Do you offer personalized solutions or sample answers for assignments?",
            content:
                `At Turbo Assignment we go above and beyond for you. This includes giving you custom solutions made just for your assignment.
                It’s a 500 word masterpiece that’s created with the intent of clearing up any and all confusion you may have. 
                They’re made to meet academic criteria but still give you a detailed answer so you can better understand what it is you’re even doing."`,
        },
        {
            title: "How do you guarantee the privacy and security of my information?",
            content: `At Turbo Assignment, we make sure your information is kept secure. 
            Upholding the confidentiality of your data is our top priority. 
            We take pride in providing guarantees that no one will access any of your information on this site. `,
        },
        {
            title: "Do technical assignments cost the same as non-technical assignments?",
            content: `Our rates are competitive. Although the amount can change depending on the assignment, 
            keep in mind that you’ll always be receiving the best value for your investment. When it comes to technical tasks, 
            they usually require a lot of research and expertise. These additional steps can make them more expensive. 
            But again, it’s still a great value so you won’t have to worry about emptying your pockets. 
            Our mission is to help you succeed academically. 
            So whether it's technical or non-technical, 
            we offer top-notch solutions tailored just for you!`,
        },
    ],
};


  export default function FAQ2() {
    return (
      <Wrapper>
        <Container>
          <ContentWrapper>
            <h3>Frequently Asked Questions</h3>
            <p>Find answers to common questions below.</p>
          </ContentWrapper>
          <Faq data={data}  />
        </Container>
      </Wrapper>
    );
  }
  
  const Wrapper = styled.div`
  background-color: #fff;
  padding: 40px 20px; /* Adjust padding for mobile */
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;
  background-color: #fff;

  h3 {
    font-size: 30px;
    color: #333;
    margin-bottom: 16px;
  }

  p {
    color: #666;
  }

  @media (min-width: 768px) {
    padding: 0 30px; /* Adjust padding for larger screens */
  }
`;
