import React, { useState } from 'react'
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";
import "./sample.css"

const SampleQuiz = () => {
    const [Show, setShow] = useState(false);
    return (
        <>      
            <div className='conatiner'>
                <ButtonSection>
                    <ButtonItem>
                        <QuizButtonsLink to="#"><NavDropDownItemText>Programming</NavDropDownItemText><BsArrowRightShort
                            size={20}
                            className="nav-dropdownitem-arrow"
                        />
                        </QuizButtonsLink>
                        <QuizButtonsLink to="#"><NavDropDownItemText>Code</NavDropDownItemText><BsArrowRightShort
                            size={20}
                            className="nav-dropdownitem-arrow"
                        />
                        </QuizButtonsLink>
                        <QuizButtonsLink to="#"><NavDropDownItemText>Assigment</NavDropDownItemText><BsArrowRightShort
                            size={20}
                            className="nav-dropdownitem-arrow"
                        /></QuizButtonsLink>
                        <QuizButtonsLink to="#"><NavDropDownItemText>Question</NavDropDownItemText><BsArrowRightShort
                            size={20}
                            className="nav-dropdownitem-arrow"
                        /></QuizButtonsLink>
                        <QuizButtonsLink to="#"><NavDropDownItemText>Security</NavDropDownItemText><BsArrowRightShort
                            size={20}
                            className="nav-dropdownitem-arrow"
                        /></QuizButtonsLink>
                        <QuizButtonsLink to="#"><NavDropDownItemText>Marketing</NavDropDownItemText><BsArrowRightShort
                            size={20}
                            className="nav-dropdownitem-arrow"
                        /></QuizButtonsLink>
                    </ButtonItem>
                </ButtonSection>
                <section className='main-div'>
                    <h1>questions and answers</h1>
                    <div className='main-heading'>
                        <p onClick={() => setShow(!Show)}>{Show ? "➖" : "➕"}</p>
                        <h3>hh</h3>
                    </div>
                    {Show && <p className='answers'></p>}
                    <div className='main-heading'>
                        <p onClick={() => setShow(!Show)}>{Show ? "➖" : "➕"}</p>
                        <h3>hh</h3>
                    </div>
                    {Show && <p className='answers'></p>}
                    <div className='main-heading'>
                        <p onClick={() => setShow(!Show)}>{Show ? "➖" : "➕"}</p>
                        <h3>hh</h3>
                    </div>
                    {Show && <p className='answers'></p>}
                    <div className='main-heading'>
                        <p onClick={() => setShow(!Show)}>{Show ? "➖" : "➕"}</p>
                        <h3>hh</h3>
                    </div>
                    {Show && <p className='answers'></p>}
                    <div className='main-heading'>
                        <p onClick={() => setShow(!Show)}>{Show ? "➖" : "➕"}</p>
                        <h3>hh</h3>
                    </div>
                    {Show && <p className='answers'></p>}
                    <div className='main-heading'>
                        <p onClick={() => setShow(!Show)}>{Show ? "➖" : "➕"}</p>
                        <h3>hh</h3>
                    </div>
                    {Show && <p className='answers'></p>}
                    <div className='main-heading'>
                        <p onClick={() => setShow(!Show)}>{Show ? "➖" : "➕"}</p>
                        <h3>hh</h3>
                    </div>
                    {Show && <p className='answers'></p>}
                    <div className='main-heading'>
                        <p onClick={() => setShow(!Show)}>{Show ? "➖" : "➕"}</p>
                        <h3>hh</h3>
                    </div>
                    {Show && <p className='answers'></p>}
                    <div className='main-heading'>
                        <p onClick={() => setShow(!Show)}>{Show ? "➖" : "➕"}</p>
                        <h3>hh</h3>
                    </div>
                    {Show && <p className='answers'></p>}
                    <div className='main-heading'>
                        <p onClick={() => setShow(!Show)}>{Show ? "➖" : "➕"}</p>
                        <h3>hh</h3>
                    </div>
                    {Show && <p className='answers'></p>}
                    <div className='main-heading'>
                        <p onClick={() => setShow(!Show)}>{Show ? "➖" : "➕"}</p>
                        <h3>hh</h3>
                    </div>
                    {Show && <p className='answers'></p>}
                    <div className='main-heading'>
                        <p onClick={() => setShow(!Show)}>{Show ? "➖" : "➕"}</p>
                        <h3>hh</h3>
                    </div>
                    {Show && <p className='answers'></p>}
                </section></div>
        </>
    )
}
const ButtonSection = styled.div`
width: 30vw;
height: 600px;
padding: 50px;
position: -webkit-sticky;
position: sticky;
top: 0;
margin-right: 20px;
background-color: rgb(255, 255, 255);
border-radius: 20px;
box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
`;
const ButtonItem = styled.div`
display: flex;
flex-direction: column;
`;
const QuizButtonsLink = styled(Link)`
display: flex;
padding: 21px 15px;
width: 300px;
justify-content: space-between;
margin: 12px -34px;
border-radius: 10px;
box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 4px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
background: #fff;
color: black;
transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: rgba(113, 72, 235, 0.59);
    color: white;
`;
const NavDropDownItemText = styled.div`
  margin-right: 20px;
`;


export default SampleQuiz

