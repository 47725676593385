import React, { useEffect, useState } from "react";
import { data } from "./data";
import styled from "styled-components";
import QuizeQuestion from "../../Components/Quiz/QuizeQuestion/QuizeQuestion";
export default function QuizExamContainer({ match }) {
  const id = match.params.id;
  const [Data, setData] = useState([]);
  let SetScore = [];

  function updateScore(value) {
    SetScore = value;
  }

  useEffect(() => {
    const value = data.filter((d) => d.id === id)[0];
    setData(value.quiz_questions);
  }, [id]);

  function handleSubmit(e) {
    e.preventDefault();
    // console.log("Submit");
    let sum = 0;
    console.log(SetScore.map((d) => sum + d));
  }
  return (
    <>
      <Wrapper>
        <form onSubmit={handleSubmit}>
          {Data.map((d, key) => (
            <QuizeQuestion
              data={d}
              k={key}
              Score={updateScore}
              // score={Score}
              questions={d.questions}
            />
          ))}
          <SubmitBTN type="submit">Submit</SubmitBTN>
        </form>
      </Wrapper>
    </>
  );
}
const Wrapper = styled.div`
  background-color: #fff;
  padding: 40px 20px;
  margin: 20px;
  border-radius: 5px;
`;
const SubmitBTN = styled.button``;
