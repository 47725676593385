import React from 'react'
import { useEffect, useState } from "react";
import { useParams, } from "react-router"
import { Link, useHistory } from "react-router-dom"

const QuizEdit = () => {
    const { quizid } = useParams();
    // const [quizdata, quizdatachange] = useState({})
    useEffect(() => {
        fetch('http://localhost:8000/Quiz/' + quizid).then((res) => {
            return res.json();
        }).then((resp) => {
            idchange(resp.id);
            questionchange(resp.question)
            answerchange(resp.answer)
        }).catch((err) => {
            console.log(err.message)
        })
    }, [quizid]);
    const [id, idchange] = useState("");
    const [question, questionchange] = useState("");
    const [answer, answerchange] = useState("");
    // const [active, activechange] = useState(true);
    const [validation, valchange] = useState(false);
    // const navigate = useNavigate();
    const history = useHistory();
    const handleSubmit = (e) => {

        e.preventDefault();
        const quizdata = { id, question, answer };

        history.push('/admin/quizList')
        fetch("http://localhost:8000/Quiz/" + quizid, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(quizdata)
        }).then((res) => {
            alert('saved successfully..')
        }).catch((err) => {
            console.log(err.message)
        })
    }
    return (
        <div className="row" >
            <div className="offset-lg-3 col-lg-6">
                <form className="container" onSubmit={handleSubmit}>
                    <div className="card" style={{ "textAlign": "left" }}>
                        <div className="card-title">
                            <h2> Quiz Edit</h2>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Id</label>
                                        <input value={id} disabled="disabled" className="form-control"></input>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Question</label>
                                        <input required value={question} onMouseDown={e => valchange(true)} onChange={e => questionchange(e.target.value)} className="form-control"></input>
                                        {question.length === 0 && validation && <span className="text-danger">Enter The Question</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Answer</label>
                                        <input value={answer} onChange={e => answerchange(e.target.value)} className="form-control"></input>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <button className="btn btn-success" type="Submit">Save</button>
                                        <Link to="/Admin/quizList" className="btn btn-danger">Back</Link>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>




    );
}

export default QuizEdit;