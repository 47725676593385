/**
 * creating Faq component for the website
 * importing statements 
 */
import React from "react";
import styled from "styled-components";
import Faq from "react-faq-component";
import { Helmet } from "react-helmet";
import AssignmentFaq from "./AssignmentFaq";


const data = {
   
    rows: [
        {
            title: "Which kind of services does TurboAssignment provides ?",
            content: `We exist to serve your academic needs. No matter what you need, 
            we can help with it. We do assignments, essays, thesis support, and much much more. 
            Our work is of the highest quality and is sure to get you a good grade in no time at all.`,
        },
        {
            title: "Does your team provides plag-free assignments solutions ?",
            content:
                `For sure! <b>Turbo Assignment</b> take pride in our work knowing that we are able to deliver assignments that are 100% plagiarism-free. 
                Your work is crafted uniquely, this means that it is original and full of academic integrity.`,
        },
        {
            title: "How did TurboAssignment come into existence?",
            content: `<b>TurboAssignment</b> began its journey over five years ago with the aim of revolutionizing global academic support. 
            Since then, we have sought to offer unrivalled assistance across continents. 
            Our tale is about passion, devotion and the enhancement of learning experience. 
            Come along on this educational journey where <b>"Excellence meets Innovation!"</b> `,
        },
        {
            title: "What are the contact details for TurboAssignment?",
            content: `For direct communication, you can reach us on WhatsApp at <a href="https://api.whatsapp.com/send?phone=918690406079">+91-8690406079</a> or drop us an email at <a href=mailto:requin.guru@gmail.com
            >requin.guru@gmail.com</a>
            We're here to assist you promptly and efficiently!`,
        },
    ],
};


  export default function FAQ1() {
    return (
      <>
      <Helmet>
      <title>FAQ's</title>
    </Helmet>
      <Wrapper>
        <Container>
          <ContentWrapper>
            <h3>Common Questions by Students</h3>
            <p>Find answers to common questions below.</p>
          </ContentWrapper>
            <Faq data={data}  />
         </Container>
         <AssignmentFaq></AssignmentFaq>
      </Wrapper>
      </>
    );
  }
  
  const Wrapper = styled.div`
  background-color: #fff;
  padding: 40px 20px; /* Adjust padding for mobile */
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;
  background-color: #fff;

  h3 {
    font-size: 30px;
    color: #333;
    margin-bottom: 16px;
  }

  p {
    color: #666;
  }

  @media (min-width: 768px) {
    padding: 0 30px; /* Adjust padding for larger screens */
  }
`;
