import React from "react";
// import { Link } from "react-router-dom";
// import styled from "styled-components";
import SampleQuiz from "../Components/samplepage/SampleQuiz";
export default function About() {
  return (
    <>
     <SampleQuiz/>
    </>
  );
}
