import React from 'react'
import { useEffect, useState } from "react";
import { Link,useHistory} from "react-router-dom";
import * as XLSX from "xlsx";
// import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
const QuizList = () => {
    const [data, setData] = useState(null);
    const history = useHistory();
    const LoadEdit = (id) => {

        history.push('/Admin/quiz/edit/' + id)
    }
    const Removefunction = (id) => {
        if (window.confirm('Do you want to remove?')) {
            fetch("http://localhost:8000/Quiz/" + id, {
                method: "DELETE"
            }).then((res) => {
                alert('Removed successfully..')
                window.location.reload()
            }).catch((err) => {
                console.log(err.message)
            })
        }
    }
    useEffect(() => {
        fetch('http://localhost:8000/Quiz').then((res) => {
            return res.json();
        }).then((resp) => {
            setData(resp);
        }).catch((err) => {
            console.log(err.message)
        })
    }, []
    )

    const handleFileUpload = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const binaryStr = event.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: true });
            setData(sheetData);
        };
        reader.readAsBinaryString(file);
    };

    const handleSave = () => {
        for (let y = 0; y < data.length; y++) {
            fetch('http://localhost:8000/Quiz', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data[y])
            })

                .then((response) => {
                    window.location.reload()
                    response.json()
                })
                .then((json) => console.log(json))
                .catch((error) => console.error(error));
        };
    }

    return (
        <div className="container" >
            <div className="card">
                <div className="card-title">
                    <h2>Question And Answer</h2></div>
            </div>
            <div className="card-body">
                <div className="divbtn">
                    <Link to="quiz/create" className="btn btn-success">Add New(+)</Link>
                    <input  type="file" onChange={handleFileUpload} />
                    <button className="btn btn-success" onClick={handleSave}>Save to API</button>
                </div>
                <table className="table table-bordered">
                    <thead className="bg-dark text-white">
                        <tr>
                            <td>Id</td>
                            <td>Question</td>
                            <td>Answer</td>
                            <td>Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data &&
                            data.map(item => (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.question}</td>
                                    <td>{item.answer}</td>
                                    <td>
                                        <button onClick={() => { LoadEdit(item.id) }} className="btn btn-success">Edit</button>
                                        <button onClick={() => { Removefunction(item.id) }} className="btn btn-danger">Remove</button>

                                    </td>
                                </tr>
                            ))
                        }

                    </tbody>

                </table>
            </div>

        </div>

    );
}

export default QuizList;