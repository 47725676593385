import React from "react";
import { FiMail, FiPhone } from "react-icons/fi";
import styled from "styled-components";

export default function Networking() {
  return (
    <>
      <Wrapper>
        <Container>
          <ContentWrapper>
            <div>
              <h3>Get a quote</h3>
              <p>Fill the form and Team will be get back to you witin 24hrs.</p>
            </div>
            <div>
              <Links>
                <FiMail size={20} />
                <div>Sample@gmail.com</div>
              </Links>
              <Links>
                <FiPhone />
                <div>+919352220187</div>
              </Links>
            </div>
          </ContentWrapper>
          <Contactform>
            <InputContainer>
              <div>Your Name</div>
              <input type="text" placeholder="Name" />
            </InputContainer>
            <InputContainer>
              <div>Mail</div>
              <input type="email" placeholder="Mail" />
            </InputContainer>
            <InputContainer>
              <div>Message</div>
              <textarea type="text" placeholder="Message"></textarea>
            </InputContainer>
            <Button>Send Message</Button>
          </Contactform>
        </Container>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  padding: 40px;
`;
const Container = styled.div`
  width: 100%;
  max-width: 1172px;
  margin: 0 auto;
  box-shadow: rgb(0 0 0 / 20%) 0px 10px 20px;
  padding: 80px 30px;
  border-radius: 10px;
  background-color: #02044a;
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-around;
`;
const Contactform = styled.form`
  width: 300px;
  border: 1px solid #efefef;
  padding: 20px 30px;
  border-radius: 5px;
  background-color: white;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 100%;
  margin: 0 auto;
  textarea {
    height: 100px;
    padding: 12px;
    border-radius: 5px;
    font-family: "Lato", sans-serif;
    margin-top: 8px;
    outline: none;
    background-color: #f9f9fb;
    border: 1px solid #efefef;
    margin-bottom: 20px;
    transition: background-color 200ms ease, outline 200ms ease,
      color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease;
    :focus {
      background-color: #fff;
      border-color: rgba(112, 71, 234, 0.4);
      -webkit-box-shadow: 0 0 0 4px rgb(112 71 234 / 10%);
      box-shadow: 0 0 0 4px rgb(112 71 234 / 10%);
      transition: background-color 200ms ease, outline 200ms ease,
        color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease;
    }
  }
  input {
    padding: 12px;
    border: 1px solid #efefef;
    border-radius: 5px;
    margin-top: 8px;
    outline: none;
    margin-bottom: 20px;
    transition: background-color 200ms ease, outline 200ms ease,
      color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease;
    :focus {
      background-color: #fff;
      border-color: rgba(112, 71, 234, 0.4);
      -webkit-box-shadow: 0 0 0 4px rgb(112 71 234 / 10%);
      box-shadow: 0 0 0 4px rgb(112 71 234 / 10%);
      transition: background-color 200ms ease, outline 200ms ease,
        color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease;
    }
  }
`;

const Button = styled.button`
  color: white;
  /* display: inline; */
  padding: 13px 20px;
  background-color: #0c74ff;
  cursor: pointer;
  /* width: 100%; */
  border-radius: 5px;
  border: none;
  margin-top: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  color: white;
  justify-content: space-between;
  h3 {
    font-size: 30px;
    margin-bottom: 16px;
  }
  p {
    color: #ffffffa1;
  }
`;

const Links = styled.div`
  align-items: center;
  display: flex;
  padding: 18px;
  padding-left: 0;
  margin: 12px 0;
  cursor: pointer;
  div {
    margin-left: 14px;
  }
`;
