import React from "react";
// import { Link } from "react-router-dom";
// import styled from "styled-components";
import Question from "../Components/accordians/Question";
export default function About() {
  return (
    <>
     <Question/>
    </>
  );
}
