import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { RiArrowDropDownLine } from "react-icons/ri";
export default function PostTagSidebar(params) {
  const [Height, setHeight] = useState("0px");
  const Accordian = (e) => {
    e.preventDefault();
    if (Height === "0px") {
      setHeight("400px");
    } else {
      setHeight("0px");
    }
  };
  return (
    <>
      <PostTagContainer>
        <PostTagWrapper>
          <Topic onClick={Accordian}>
            Tags
            <RiArrowDropDownLine />
          </Topic>
          <ListWrapper style={{ maxHeight: Height, transition: `0.6s ease` }}>
            {data.map((d, key) => (
              <div key={key}>
                <ListItem>{d.topic}</ListItem>
              </div>
            ))}
          </ListWrapper>
        </PostTagWrapper>
      </PostTagContainer>
    </>
  );
}
const PostTagContainer = styled.div`
  background-color: white;
  margin: 0 20px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const PostTagWrapper = styled.div`
  min-width: 238px;

  @media only screen and (max-width: 768px) {
    min-width: auto;
  }
`;

const Topic = styled.div`
  padding: 12px 22px;
  color: black;
  text-transform: uppercase;

  @media only screen and (max-width: 1024px) {
    border-radius: 3px;
    border: 1px solid #3d3d4e3d;
    align-items: center;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  div {
    cursor: pointer;
    padding: 12px 22px;
    transition: ease 0.2s;

    :hover {
      a {
        color: #7047ea;
      }
      border-radius: 40px;
      background: #7047ea26;
    }
  }
`;

const ListItem = styled(Link)`
  color: #3d3d4e;
`;

const data = [
  { topic: "All" },
  {
    topic: "Cloud Computing",
  },
  {
    topic: "Cloud Technologies",
  },
  {
    topic: "Programming",
  },
  {
    topic: "Computer",
  },
  {
    topic: "Technologies",
  },
  {
    topic: "Design",
  },
  {
    topic: "Security",
  },
];
