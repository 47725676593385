import React, { useEffect } from "react"
import AOS from "aos";
import "aos/dist/aos.css";
import { TawakAPI } from "./util";
import "./App.css";
import Routes from "./Router/Routes";

AOS.init();
function App() {
  
  const loadScript=(src)=>{
    return new Promise((resolve)=>{
      const script=document.createElement('script')
      script.src=src
      script.onload=()=>{
        resolve(true)
      }
      script.onerror=()=>{
        resolve(false)
      }
      
      document.body.appendChild(script)
    })
  }
  useEffect(()=>{ 
    loadScript("https://checkout.razorpay.com/v1/checkout.js")
  })
  useEffect(() => {
    TawakAPI();
  }, []);

  return (
    <div className="App">
      <Routes />  
    </div>
  );
}

export default App;
