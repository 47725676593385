import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import {  useHistory } from "react-router-dom";
// import * as XLSX from "xlsx";
const QuizCreate = () => {
    const history = useHistory();
    const [id, idchange] = useState("");
    const [question, questionchange] = useState("");
    const [answer, answerchange] = useState("");
    // const [active, activechange] = useState(true);
    const [validation, valchange] = useState(false);
    // const navigate = useNavigate();
    const handleSubmit = (e) => {

        e.preventDefault();
        const quizdata = { question, answer };

        history.push('/Admin/quizlist')
        fetch("http://localhost:8000/Quiz", {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(quizdata)
        }).then((res) => {
            alert('saved successfully..')
        }).catch((err) => {
            console.log(err.message)
        })
    }

    return (<div>
        <div className="row">
            <div className="offset-lg-3 col-lg-6">
                <form className="container" onSubmit={handleSubmit}>
                    <div className="card" style={{ "textAlign": "left" }}>
                        <div className="card-title">
                            <h2> Quiz Create</h2>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Id</label>
                                        <input value={id} onMouseDown={e => valchange(true)} onChange={e => idchange(e.target.value)} className="form-control"></input>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Question</label>
                                        <input required value={question} onMouseDown={e => valchange(true)} onChange={e => questionchange(e.target.value)} className="form-control"></input>
                                        {question.length === 0 && validation && <span className="text-danger">Enter The Question</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Answer</label>
                                        <input required value={answer} onChange={e => answerchange(e.target.value)} className="form-control"></input>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <button className="btn btn-success" type="Submit">Save</button>
                                        <Link to="/" className="btn btn-danger">Back</Link>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>


    </div>);
}

export default QuizCreate;