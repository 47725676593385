export const data = [
  {
    id: "asd123",
    name: "Test 1",
    questions: 5,
    time: 60,
    quiz_questions: [
      {
        quiz: "What is abbreviation of HTML?",
        option: [
          "Hyper Type Multi Language",
          "Higher Text Multiple Language",
          "Hyper Text Markup Language",
          "Hollow Type Markup Language ",
        ],
        answer: "3",
      },
      {
        quiz: "How many types of markup in HTML?",
        option: [
          "Both",
          "1 - opening and closing markup only",
          "1 - self closing markups only",
          "None of these ",
        ],
        answer: "1",
      },
      {
        quiz: "<iframe> is HTML5 markup",
        option: ["False", "True", "Neither true nor false", "Not Sure"],
        answer: "2",
      },
      {
        quiz: "<div> and <span> are inline elements?",
        option: ["True", "False", "Neither True nor false", "None of these "],
        answer: "3",
      },
      {
        quiz: "HTML must need body markup. Why? Because:",
        option: [
          "It did'nt needs to show the markups inside it.",
          "It needs to show the markups inside it.",
          "It needs <head> element",
          "None of these ",
        ],
        answer: "2",
      },
    ],
  },
  {
    id: "asd1234",
    name: "Test 2",
    questions: 10,
    time: 120,
    quiz_questions: [
      {
        quiz: "The external JavaScript file must contain the <script> tag.",
        option: {},
        option1: "True",
        option2: "False",
        option3: "Neither true nor false",
        option4: "None of above",
        answer: "1",
      },
      {
        quiz: "Choose the correct HTML element for the largest heading:",
        option1: "<h6>",
        option2: "<heading>",
        option3: "<h1>",
        option4: "<head>",
        answer: "3",
      },
      {
        quiz: "What is the correct HTML element for inserting a line break?",
        option1: "<break>",
        option2: "<br>",
        option3: "<lnbr>",
        option4: "none of above",
        answer: "2",
      },
      {
        quiz: "What is the correct HTML for adding a background color?",
        option1: "<background>yellow</background>",
        option2: '<body style="bg-color:yellow;">',
        option3: '<body bg="yellow">',
        option4: '<body style="background-color:yellow;">',
        answer: "4",
      },
      {
        quiz: "Choose the correct HTML element to define important text",
        option1: "<strong>",
        option2: "<b>",
        option3: "<important>",
        option4: "<i>",
        answer: "1",
      },
      {
        quiz: "Choose the correct HTML element to define emphasized text",
        option1: "<i>",
        option2: "<emphasize>",
        option3: "<italic>",
        option4: "<em>",
        answer: "4",
      },
      {
        quiz: "What is the correct HTML for creating a hyperlink?",
        option1: "<a>http://www.w3schools.com</a>",
        option2: ' <a href="http://www.w3schools.com">W3Schools</a>',
        option3: '<a name="http://www.w3schools.com">W3Schools.com</a>',
        option4: '<a url="http://www.w3schools.com">W3Schools.com</a>',
        answer: "2",
      },
      {
        quiz: "Which character is used to indicate an end tag?",
        option1: "<",
        option2: "*",
        option3: "/",
        option4: "^",
        answer: "3",
      },
      {
        quiz: "How can you open a link in a new tab/browser window?",
        option1: '<a href="url" new>',
        option2: '<a href="url" target="_blank">',
        option3: '<a href="url" target="new">',
        option4: '<a href="url" target="new_blank">',
        answer: "2",
      },
      {
        quiz: "Which of these elements are all <table> elements?",
        option1: "<table><head><tfoot>",
        option2: "<table><tr><td>",
        option3: "<table><tr><tt>",
        option4: "<thead><body><tr>",
        answer: "2",
      },
    ],
  },
];
