import React from "react";
import styled, { keyframes } from "styled-components";
import { Helmet } from "react-helmet";
export default function refund  () {
  return (
    <>
            <Helmet>
                <title>Refund Policy - Turbo Assignment</title>
            </Helmet>
    <RefundPolicyContainer>
      <RefundPolicyTitle>Refund Policy</RefundPolicyTitle>
      <RefundPolicyContent>
        <p>
          Welcome to Turbo Assignment! We strive to provide top-notch assignment solutions to our international student community. Our commitment is to ensure a smooth and transparent refund process in case you encounter any issues.
        </p>

        <RefundPolicySection>
          <SectionTitle>1. Refund Eligibility</SectionTitle>
          <p>
            We understand that situations may arise where a refund is necessary. To be eligible for a refund, you must meet the following criteria:
          </p>
          <ul>
            <li>The assignment solution provided does not meet the specified requirements.</li>
            <li>Technical issues prevent you from accessing or downloading the assignment solution.</li>
            <li>Payment was processed erroneously, leading to overpayment.</li>
          </ul>
        </RefundPolicySection>

        <RefundPolicySection>
          <SectionTitle>2. Refund Process</SectionTitle>
          <p>
            To initiate a refund, please follow these steps:
          </p>
          <ol>
            <li>Contact our support team at support@turboassignment.com within 7 days of receiving the assignment solution.</li>
            <li>Provide detailed information about the issue and reasons for the refund request.</li>
            <li>Our team will review your request and, if eligible, process the refund within 14 days.</li>
          </ol>
        </RefundPolicySection>

        <RefundPolicySection>
          <SectionTitle>3. Non-Refundable Items</SectionTitle>
          <p>
            Certain circumstances may not qualify for a refund, including:
          </p>
          <ul>
            <li>If the assignment solution meets the specified requirements but you change your mind.</li>
            <li>Failure to submit necessary information or details required for the assignment.</li>
          </ul>
        </RefundPolicySection>

        <RefundPolicySection>
          <SectionTitle>4. Contact Us</SectionTitle>
          <p>
            If you have any questions about our refund policy, please contact us at <a href="https://api.whatsapp.com/send?phone=918690406079">+91-8690406079</a> or drop us an email at 
                <a href="mailto:requin.guru@gmail.com"> requin.guru@gmail.com</a>.
          </p>
        </RefundPolicySection>
      </RefundPolicyContent>
    </RefundPolicyContainer>
    </>
  );
};

// Styled components
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const RefundPolicyContainer = styled.div`
  background-color: white;
  color: #000000cc;
  padding: 40px;
  animation: ${fadeIn} 0.5s ease-in-out;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
`;

const RefundPolicyTitle = styled.h2`
  font-size: 28px;
  color: #7047eac7;
  margin-bottom: 20px;
  text-align: center;
  animation: ${slideInFromLeft} 1s ease-in-out;
`;

const RefundPolicyContent = styled.div`
  p {
    line-height: 160%;
    margin-bottom: 20px;
    animation: ${slideInFromLeft} 1s ease-in-out;
  }
`;

const RefundPolicySection = styled.div`
  margin-bottom: 30px;
  animation: ${slideInFromLeft} 1s ease-in-out;
`;

const SectionTitle = styled.h3`
  font-size: 22px;
  color: #7047eac7;
  margin-bottom: 15px;
  animation: ${slideInFromLeft} 1s ease-in-out;
`;
