import React from 'react'
import QuizItem from '../QuizItem/QuizItem'
export default function QuizeList(params) {
  return (
    <>
      <div>
        <QuizItem />
      </div>
    </>
  )
}
