import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function TermsCondition() {
    return (
        <>
            <Helmet>
                <title>Terms and Conditions - Turbo Assignment</title>
            </Helmet>
            <TermsIntro>

                <RequinTermsIntroHead>Terms and Conditions</RequinTermsIntroHead>
                <RequinTermsIntroSummry>
                    Welcome to Turbo Assignment! Please read these terms carefully before using our site.
                </RequinTermsIntroSummry>
            </TermsIntro>

            <TurboContent>
                <TurboSect>
                    <TermsHeading>For Users</TermsHeading>
                    <TermsText>
                        Welcome to Turbo Assignment! As a user, your experience is important to us, and we want to ensure a secure and productive environment for everyone.

                        <br /><br />

                        When you register on Turbo Assignment, you become an integral part of our platform. To make the most of your experience, here are some guidelines to follow:

                        <br /><br />

                        <strong>Provide Accurate Information:</strong> Ensure that the information you provide during registration is accurate and up-to-date. This helps us tailor our services to your needs and ensures effective communication throughout the process.

                        <br /><br />

                        <strong>Keep Your Login Details Safe:</strong> Your login credentials are your gateway to Turbo Assignment. Keep your username and password confidential to prevent unauthorized access. If you suspect any unauthorized use of your account, inform us immediately to take prompt action.

                        <br /><br />

                        <strong>Immediate Reporting of Issues:</strong> If you encounter any issues or anomalies on the platform, report them to us immediately. Your feedback is valuable, and we are committed to resolving any issues promptly to enhance your user experience.

                        <br /><br />

                        <strong>Responsibility for Information Accuracy:</strong> While we strive to maintain accurate information on our platform, users are responsible for the accuracy of the information they provide. We are not liable for any losses or inconveniences arising from incorrect information provided by users.

                        <br /><br />

                        <strong>Unauthorized Access and Tampering:</strong> Users must refrain from attempting to access unauthorized areas of the platform or tampering with information. Any such activities violate our terms of use and may result in account suspension or legal action.

                        <br /><br />

                        Turbo Assignment is committed to fostering a collaborative and secure community. By adhering to these guidelines, you contribute to a positive user experience for yourself and fellow users. Thank you for being a part of our platform!
                    </TermsText>
                </TurboSect>

                <TurboSect>
                    <TermsHeading>Our Agreement With You</TermsHeading>
                    <TermsText>
                        At Turbo Assignment, our commitment is to facilitate a seamless connection between you and our pool of experts, ensuring the delivery of original and high-quality work. We strive to match your requirements with the most suitable experts who possess the expertise needed for your specific assignment.
                        <br /><br />
                        Once an expert is assigned and payment is received, it indicates the initiation of the collaboration process. It's important to note that, due to the nature of our services and the involvement of our experts, refunds are generally not possible once this stage is reached. We encourage you to thoroughly review the assignment details before confirming an expert.
                        <br /><br />
                        Amendments to the work are considered and accommodated if necessary. We understand that revisions may be required to meet your expectations fully. However, please be aware that substantial changes to the original order may be subject to additional terms, and our team will communicate any such adjustments.
                        <br /><br />
                        To maintain the integrity of our service, direct communication with our experts is not permitted. All communications, inquiries, or requests related to the assignment should be directed through our platform. This ensures a centralized and efficient channel of communication, allowing us to manage the process effectively.
                        <br /><br />
                        The agreement between you and Turbo Assignment remains in effect until the job is successfully completed or terminated by either party. Successful completion is defined by the delivery of the final work to your satisfaction. In the event of termination, both parties are expected to adhere to any remaining obligations outlined in the agreement.
                    </TermsText>
                </TurboSect>

                <TurboSect>
                    <TermsHeading>Outstanding Balance</TermsHeading>
                    <TermsText>
                        Pay the balance 24 hours before the order's due. Failure to pay may delay delivery.
                    </TermsText>
                </TurboSect>

                <TurboSect>
                    <TermsHeading>Right and Detailed Information</TermsHeading>
                    <TermsText>
                        Provide all necessary details promptly to avoid delays. We may contact you for confirmation or additional information.
                    </TermsText>
                </TurboSect>

                <TurboSect>
                    <TermsHeading>Privacy Law</TermsHeading>
                    <TermsText>
                        Your details are confidential. We won't share them without legal requirements.
                    </TermsText>
                </TurboSect>

                {/*  */}
                <TurboSect>
                    <TermsHeading>Customer Satisfaction: Perfecting Finished Assignments</TermsHeading>
                    <TermsText>
                        Sometimes things just don’t go as planned, and you’re left with a bad product. Hopefully that won’t be the case here. We’re open to fixing your project for free if it doesn’t follow the guidelines. Just make a request within 7 days that we first delivered our work, and it’ll be done. A new due date will be given after you’ve made the request.

                        <br /><br />

                        If you and the expert can’t agree on something, there will be a quality control team that’ll intervene. They’ll look over everything and they have the last say.

                        <br /><br />

                        If you need changes to be made after seven days of delivery we’ll treat it as a separate request and charge accordingly.
                    </TermsText>
                </TurboSect>
                <TurboSect>
                    <TermsHeading>General</TermsHeading>
                    <TermsText>
                        Use the site at your own risk. We are closed on public holidays. Check your university guidelines. We provide academic support, not professional advice. We aren't liable for interruptions or delays in accessing the site. Disputes are subject to the laws of India or other applicable regulatory authority..
                    </TermsText>
                </TurboSect>

                <TurboSect>
                    <TermsHeading>Termination</TermsHeading>
                    <TermsText>
                        Turbo Assignment has the right to end your service with us. We can end it in full or just a part of it at any time. As well as ending your account and your site usage without warning. If we decide to end your service, we will notify you either by an update on the site, or through direct communication such as an email. This is what could happen to you if you broke the terms before we ended things yourself.
                    </TermsText>
                </TurboSect>
            </TurboContent>

            <ConclusionSection>
                <p>
                    For more details, including refund policies, visit our <Link to="/refund-policy">Refund Policy</Link>.
                </p>

            </ConclusionSection>
        </>
    );
}

const ThemeColor = "#7047ea"; // Your theme color

const TermsIntro = styled.div`
  background-color: ${ThemeColor};
  color: white;
  padding: 40px;
  text-align: center;
`;

const RequinTermsIntroHead = styled.h2`
  font-size: 32px;
  margin-bottom: 20px;
`;

const RequinTermsIntroSummry = styled.p`
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 0;
`;

const TurboContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  color: #000000cc;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(3, 27, 78, 0.1);
  margin-top: 20px;
`;

const TurboSect = styled.div`
  margin-bottom: 20px;
`;

const TermsHeading = styled.h3`
  font-size: 24px;
  margin-bottom: 10px;
`;

const TermsText = styled.p`
  font-size: 16px;
  line-height: 160%;
`;

const ConclusionSection = styled.div`
  text-align: center;
  margin-top: 30px;
`;

// const AgreeButton = styled(Link)`
//   padding: 15px 30px;
//   font-size: 18px;
//   background-color: ${ThemeColor};
//   color: white;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   text-decoration: none;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: #5a35c4;
//   }
// `;

// Placeholder image styles
// const TermsImage = styled.img`
//   width: 100%;
//   max-width: 400px;
//   margin: 20px auto;
//   border-radius: 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// `;
