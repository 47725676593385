import React from "react";
// import { BrowserRouter, Routes, Route } from "react-router-dom"
import { PostContainer, MainLayout } from "../Containers/index";
import { PrivateRoute, AppRoute } from "../Router/index";
import Protected from "./Protected"
import { Route, Switch } from "react-router-dom";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import {
  Blog,
  Checkout,
  About,
  OrdersPage,
  HomePage,
  LoginPage,
  RegisterPage,
  Qurry,
  QuizContainer,
  Error,
  refund,
  AdjustmentPolicy,
  AdminLogin,
  AdminRegister,
  QuizCreate,
  QuizEdit,
  Networking,
  AustraliaPage,
  unitedkingdom,
  Singapore,
  newzealand,
  Germany,
  Canada,
  FAQ1,
  termsCondition,
  QuizList,
  UnitedStatesPage
} from "../pages/index";
import QuizExamContainer from "../Containers/Quiz/QuizExamContainer";

import Contact from "../pages/Contact";
export default function Routes(_params) {
  return (
    <Switch>
      <AppRoute exact path="/" layout={MainLayout} component={HomePage} />
      <AppRoute exact path="/about" layout={MainLayout} component={About} />
      <AppRoute exact path="/login" layout={MainLayout} component={LoginPage} />
      <AppRoute exact path="/Admin" layout={MainLayout} component={AdminLogin} />
      <AppRoute exact path="/networking" layout={MainLayout} component={Networking} />
      <AppRoute exact path="/Admin/register" layout={MainLayout} component={AdminRegister}/>
      <Route path="/Admin/quizlist"><Protected Cmp={QuizList}/></Route>
      <Route path="/Admin/quiz/create"><Protected Cmp={QuizCreate}/></Route>
      <Route path="/Admin/quiz/edit/:quizid"><Protected Cmp={QuizEdit }/></Route>
      <AppRoute exact path="/Qurry" layout={MainLayout} component={Qurry} />
      <AppRoute
        exact
        path="/register"
        layout={MainLayout}
        component={RegisterPage}

      />
      <AppRoute exact path="/blog" layout={MainLayout} component={Blog} />
      <AppRoute exact path="/refund-policy" layout={MainLayout} component={refund}/>
      <AppRoute
        exact
        path="/blog/:uid"
        layout={MainLayout}
        component={PostContainer}
      />
      <PrivateRoute
        exact
        path="/checkout"
        layout={MainLayout}
        component={Checkout}
      />
      <PrivateRoute
        exact
        path="/orders"
        layout={MainLayout}
        component={OrdersPage}
      />
      <PrivateRoute
        exact
        path="/quiz"
        layout={MainLayout}
        component={QuizContainer}
      />
      <PrivateRoute
        exact
        path="/quiz/:id/start"
        layout={MainLayout}
        component={QuizExamContainer}
      />
      <AppRoute exact path="/Contact" layout={MainLayout} component={Contact} />
      <AppRoute exact path="/FAQ" layout={MainLayout} component={FAQ1} />
      <AppRoute exact path="/terms" layout={MainLayout} component={termsCondition}/>
      <AppRoute exact path="/network" layout={MainLayout} component={Networking} />
      <AppRoute exact path ="/adjustment" layout={MainLayout}component={AdjustmentPolicy}/>
      <AppRoute exact path="/aus" layout={MainLayout} component={AustraliaPage}/>
      <AppRoute exact path="/united" layout={MainLayout} component={UnitedStatesPage}/>
      
      <AppRoute exact path="/uk" layout={MainLayout} component={unitedkingdom}/>
      <AppRoute exact path="/germany" layout={MainLayout} component={Germany}/>
      <AppRoute exact path="/singa" layout={MainLayout} component={Singapore}/>
      <AppRoute exact path="/canada" layout={MainLayout} component={Canada}/>
      <AppRoute exact path="/newz" layout={MainLayout} component={newzealand}/>

      <AppRoute path="/404" layout={MainLayout} component={Error} />
      {/* <Redirect from="*" to="/404" /> */}
    </Switch>
  );
}
