import React from "react";
import { FiMail, FiPhone } from "react-icons/fi";
import styled from "styled-components";
import { Helmet } from "react-helmet";
export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact Us -Turbo Assignment</title>
      </Helmet>
      <Wrapper>
        <Container>
          <ContentWrapper>
            <div>
              <h3>Get a quote</h3>
              <p>Fill the form and Team will be get back to you witin 24hrs.</p>
            </div>
            <div>
              <Links>
                <FiMail size={20} />
                <div>requin.guru@gmail.com</div>
              </Links>
              <Links>
                <FiPhone />
                <div>+91-8690406079</div>
              </Links>
            </div>
          </ContentWrapper>
          <Contactform>
            <InputContainer>
              <div>Your Name</div>
              <input type="text" placeholder="Name" />
            </InputContainer>
            <InputContainer>
              <div>Mail</div>
              <input type="email" placeholder="Mail" />
            </InputContainer>
            <InputContainer>
              <div>Message</div>
              <textarea type="text" placeholder="Message"></textarea>
            </InputContainer>
            <Button>Send Message</Button>
          </Contactform>
        </Container>
      </Wrapper>

    </>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  padding: 40px;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1172px;
  margin: 0 auto;
  box-shadow: rgb(0 0 0 / 20%) 0px 10px 20px;
  padding: 80px 30px;
  border-radius: 10px;
  background-color: #02044a;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Contactform = styled.form`
  width: 100%;
  max-width: 400px; /* Adjust the maximum width for responsiveness */
  border: 1px solid #efefef;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 100%;
  margin: 0 auto;

  textarea,
  input {
    width: 100%;
    padding: 12px;
    border: 1px solid #efefef;
    border-radius: 5px;
    margin-top: 8px;
    outline: none;
    margin-bottom: 20px;
    transition: background-color 200ms ease, outline 200ms ease,
      color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease;

    :focus {
      background-color: #fff;
      border-color: rgba(112, 71, 234, 0.4);
      -webkit-box-shadow: 0 0 0 4px rgb(112 71 234 / 10%);
      box-shadow: 0 0 0 4px rgb(112 71 234 / 10%);
      transition: background-color 200ms ease, outline 200ms ease,
        color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease;
    }
  }
`;

const Button = styled.button`
  color: white;
  padding: 13px 20px;
  background-color: #0c74ff;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  color: white;
  margin-top: 20px;

  h3 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  p {
    font-size: 14px;
    color: #ffffffa1;
    margin-bottom: 20px;
  }
`;

const Links = styled.div`
  align-items: center;
  display: flex;
  padding: 18px;
  padding-left: 0;
  margin: 12px 0;
  cursor: pointer;

  div {
    margin-left: 14px;
  }
`;

