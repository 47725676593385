import React from "react";
import styled, { keyframes } from "styled-components";
import { Helmet } from "react-helmet";

export default function AdjustmentPolicy () {
  return (
    <>
      <Helmet>
        <title>Adjustment Guidelines - Turbo Assignment</title>
      </Helmet>
      <AdjustmentPolicyContainer>
        <AdjustmentPolicyTitle>Adjustment Guidelines</AdjustmentPolicyTitle>
        <AdjustmentPolicyContent>
          <p>
            Greetings from Turbo Assignment! Our primary aim is to deliver impeccable assignment solutions. In case you feel the need for adjustments to your order, please acquaint yourself with our Adjustment Guidelines below.
          </p>

          <PolicySection>
            <SectionTitle>1. Eligibility for Adjustments</SectionTitle>
            <p>
              You qualify for adjustments if the delivered assignment fails to meet your specified requirements or if technical issues hinder your access to the assignment solution.
            </p>
          </PolicySection>

          <PolicySection>
            <SectionTitle>2. Requesting Adjustments</SectionTitle>
            <p>
              To request adjustments, reach out to our support team at <a href="mailto:requin.guru@gmail.com"> requin.guru@gmail.com</a> within 7 days of receiving the assignment solution. Furnish detailed information about the issues and the adjustments needed. Our team will review your request and, if eligible, process the adjustments accordingly.
            </p>
          </PolicySection>

          <PolicySection>
            <SectionTitle>3. Non-Adjustable Instances</SectionTitle>
            <p>
              Adjustments may not be applicable if the assignment solution meets the specified requirements, but you change your mind or fail to submit necessary information or details required for the assignment.
            </p>
          </PolicySection>

          <PolicySection>
            <SectionTitle>4. Connect with Us</SectionTitle>
            <p>
              For any queries about our Adjustment Guidelines, please connect with us at <a href="mailto:requin.guru@gmail.com"> requin.guru@gmail.com</a> or direct through <a href="https://api.whatsapp.com/send?phone=918690406079">+91-8690406079</a>.
            </p>
          </PolicySection>
        </AdjustmentPolicyContent>
      </AdjustmentPolicyContainer>
    </>
  );
}

// Styled components
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const AdjustmentPolicyContainer = styled.div`
  background-color: #f8f8fa;
  color: #000000cc;
  padding: 40px;
  animation: ${fadeIn} 0.5s ease-in-out;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
`;

const AdjustmentPolicyTitle = styled.h2`
  font-size: 28px;
  color: #7047eac7;
  margin-bottom: 20px;
  text-align: center;
  animation: ${slideInFromLeft} 1s ease-in-out;
`;

const AdjustmentPolicyContent = styled.div`
  p {
    line-height: 160%;
    margin-bottom: 20px;
  }
`;

const PolicySection = styled.div`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h3`
  font-size: 22px;
  color: #7047eac7;
  margin-bottom: 15px;
`;


