import React from "react";
import styled from "styled-components";
import Faq from "react-faq-component";


const data = {

  rows: [
    {
      title: "How can I monitor the status of my assignment?",
      content: `Keep tabs on your assignment hassle-free! Whether through our user-friendly dashboard or your preferred communication channel, 
      <b>TurboAssignment</b> provide real-time updates and progress milestones. 
      Your academic journey, your way!`,
    },
    {
      title: "Do your assignment services come with any additional costs?",
      content:
        `Absolutely no secret, surprise costs! Our assignment services have straightforward prices. 
         What you see is what you pay. It’s that simple.`,
    },
    {
      title: "Can I ask to have my assignment written in certain style?",
      content: ` Absolutely! Your assignment, 
      your style! Just let us know your preferred writing style and 
      our experts will tailor it to your specifications.`,
    },
    {
      title: "Which qualifications do your team of experts hold?",
      content: `Our team is amazing! They have advanced degrees in a variety of fields, so you know they’re the real deal. 
      You’ll definitely get the help you need from professionals who know what they’re talking about and have the right qualifications.`,
    },
    {
      title: "Can you help in doing a group assignment or working in collaboration?",
      content: `That’s right! Whether it’s a group project or you’re just collaborating, 
      our experts are ready to help with whatever you need. 
      Teamwork makes the academic dream work.`,
    },
    {
      title: "Can I specify a preferred citation style for my assignment?",
      content: `Absolutely! Your rules, your assignment. 
      Just let us know what citation style you want everything done in and our experts will flow with your exact requirements.`,
    },
    {
      title: "Are your assignment services available for international students?",
      content: `Yes! Students from all over the world are welcome to use our <b> Turbo assignment services</b>. 
      We’re here to help you on your academic journey, no matter where you are!`,
    },
    {
      title:"Can you help in doing Thesis, Dissertation kind of work also ?",
      content:`Absolutely! From topic selection to project completion, and even presentation preparation—we've got you covered. 
      Plus, enjoy personalized one-on-one expert meetings to guide you through every step of your project journey.`
    },
  ],
};


export default function FAQ3() {
  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <h3>Queries realted to Assignment</h3>

        </ContentWrapper>
        <Faq data={data} />
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  padding: 40px 20px; /* Adjust padding for mobile */
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: centre;
  max-width: 800px;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;
  background-color: #fff;

  h3 {
    font-size: 30px;
    color: #333;
    
    margin-bottom: 16px;
  }

  p {
    color: #666;
  }

  @media (min-width: 768px) {
    padding: 0 30px; /* Adjust padding for larger screens */
  }
`;
