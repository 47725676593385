import React, { useState } from 'react'

const MyQuestion = ({ question, answer,id}) => {
    const [Show, setShow] = useState(false);
    return (
        <>
            <div className='main-heading'>
                <p onClick={() => setShow(!Show)}>{Show ? "➖" : "➕"}</p>
                <span>{id }</span>
                <h3>{question}</h3>
            </div>
            {Show && <p className='answers'>{answer}</p> }
        </>
    )
}

export default MyQuestion
